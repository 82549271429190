import React from "react"
import { Link } from "gatsby"
import { withNamespaces } from "react-i18next";

import Layout from "../components/layout"
import SEO from "../components/seo"
import NavBar from "../components/navbar"
import Footer from "../components/footer"
import Loader from "../components/loader"

import {Helmet} from "react-helmet";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]',{
		speed: 1000,
		speedAsDuration: true,
		easing: 'easeInOutCubic'
	})
}


class Contact extends React.Component {

    state = {
    }

    render() {
        const {t, i18n} = this.props;

        return (
            <Layout>
                <SEO title="Contact" />  
                <Loader />
                
                <div id="" className="hero is-relative faq">
                    <NavBar siteTitle="Atlantide IO" foceSolide/>
                    <section id="contact" className="section is-medium">
                        <div className="container">
                            
                            <div className="section-title-wrapper">
                                <div className="bg-number"><i className="material-icons">email</i></div>
                                <h2 className="section-title-landing has-text-centered dark-text">{t('title')}</h2>
                            </div>

                            <p id="msg-success">
                                {t("contact_success")}
                            </p>

                        </div>
                    </section>
                </div>
    
                <Footer />
            </Layout>
        )

    } 
}

export default withNamespaces("Contact")(Contact);
